<div mat-dialog-title>
  <h3 class="me-3">{{title}}</h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  <div [ngClass]="getClassList()">
    <mat-icon *ngIf="isWarning" class="me-3 pe-3">warning</mat-icon>
    <div class="confirm-message">{{message }}</div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="buttons-bar">
  <button mat-raised-button mat-dialog-close class="me-2">Annuler</button>
  <button mat-flat-button color="primary" mat-dialog-close="ok">
    Ok
  </button>
</mat-dialog-actions>