<!-- Newsletter -->
<div class="bg-bleu-ciel">
	<div class="mx-auto max-w-screen-lg">
		<div class="flex flex-col gap-8 px-5 py-8 md:flex-row md:justify-between xl:px-0">
			<!-- Logo and Title -->
			<div class="flex flex-col gap-3 md:basis-1/2 md:flex-row md:items-center md:gap-12">
				<!-- Logo -->
				<div class="h-12">
					<img src="assets/images/logo.svg" alt="Optim Finance Logo" class="h-full" />
				</div>
				<!-- Title -->
				<h2 class="font-inter text-h2 font-bold text-primary">Inscription à la Newsletter</h2>
			</div>
			<!-- Input and Button -->
			<div class="flex items-center gap-4 md:basis-2/5">
				<!-- Input -->
				<input type="email" placeholder="Entrer votre Email"
					class="grow rounded-md border-b border-bleu/30 bg-transparent px-8 pb-6 pt-4 text-sm text-primary placeholder:text-bleu/40 focus:border-bleu focus:outline-none focus:ring-1 focus:ring-bleu" />
				<!-- Button -->
				<button class="bg-bleu px-6 py-5 text-button font-bold uppercase text-white md:px-10">
					S'abonner
				</button>
			</div>
		</div>
	</div>
</div>
<!-- Links -->
<div class="bg-gris">
	<div class="mx-auto max-w-screen-lg">
		<div class="flex flex-col justify-between gap-y-10 px-5 py-8 md:flex-row xl:px-0">
			<!-- Services -->
			<div class="flex flex-col gap-7">
				<!-- Title -->
				<div class="relative">
					<h2 class="py-2 text-xl font-bold text-white">Nos Services</h2>
					<div class="bottom-0 left-0 h-0.5 w-12 bg-rouge"></div>
				</div>
				<!-- Links -->
				<div class="flex flex-col gap-2.5">
					<a routerLink="/" [hidden]="true" class="text-sm text-secondary hover:text-white">Nos missions</a>
					<a routerLink="/portage-salarial" class="text-sm text-secondary hover:text-white">Portage salarial</a>
					<a routerLink="/auto-entreprise" class="text-sm text-secondary hover:text-white">Auto-entreprise</a>
					<a routerLink="/gestion-de-societe" class="text-sm text-secondary hover:text-white">
						Création et gestion d'entreprise
					</a>
					<a routerLink="/" [hidden]="true" class="text-sm text-secondary hover:text-white">Offres</a>
					<a routerLink="/" [hidden]="true" class="text-sm text-secondary hover:text-white">Coaching</a>
				</div>
			</div>
			<!-- Espaces -->
			<div class="flex flex-col gap-7">


				<!-- <div class="relative">
					<h2 class="py-2 text-xl font-bold text-white">Nos Espaces</h2>
					<div class="bottom-0 left-0 h-0.5 w-12 bg-rouge"></div>
				</div>

				<div class="flex flex-col gap-2.5">
					<a routerLink="/" class="text-sm text-secondary hover:text-white">Profil et paramètres</a>
					<a routerLink="/" class="text-sm text-secondary hover:text-white">Espace consultant</a>
					<a routerLink="/" class="text-sm text-secondary hover:text-white">Espace entreprise</a>
					<a routerLink="/" class="text-sm text-secondary hover:text-white">
						Politique de confidentialité
					</a>
					<a routerLink="/documentation" class="text-sm text-secondary hover:text-white">
						Documentation
					</a>
					<a routerLink="/faq" class="text-sm text-secondary hover:text-white">
						Questions fréquentes
					</a>
				</div> -->


			</div>
			<!-- Contact -->
			<div class="flex flex-col gap-7">
				<!-- Title -->
				<div class="relative">
					<h2 class="py-2 text-xl font-bold text-white">Contact</h2>
					<div class="bottom-0 left-0 h-0.5 w-12 bg-rouge"></div>
				</div>
				<!-- General queries -->
				<div class="flex flex-col gap-5">
					<!-- Subtitle -->
					<h3 [hidden]="true" class="text-base font-bold text-white">Requêtes générales</h3>
					<!-- Links -->
					<div class="flex flex-col gap-2.5">
						<a href="tel:+33159068086" target="_blank" rel="noopener noreferrer" class="text-secondary hover:text-white">
							Tél : +33 1 59 06 80 86
						</a>
						<a href="mailto:contact@optim-finance.com" target="_blank" rel="noopener noreferrer" class="text-secondary hover:text-white">
							E-Mail : contact@optim-finance.com
						</a>
					</div>
				</div>
				<!-- Address -->
				<div class="flex flex-col gap-5">
					<!-- Subtitle -->
					<h3 class="text-base font-bold text-white">Adresse</h3>
					<!-- Links -->
					<a href="https://maps.app.goo.gl/cEp2soqhK8uJHcTk6" target="_blank" rel="noopener noreferrer" class="text-secondary hover:text-white">
						<span class="block">8 rue Boudreau, 75009 Paris</span>
					</a>
				</div>
				<!-- Socials -->
				<div class="flex gap-2">
					<a href="https://www.linkedin.com/company/optim-finance/mycompany/" target="_blank" class="flex h-12 w-12 items-center justify-center bg-black">
						<fa-icon [icon]="['fab', 'linkedin-in']" size="lg" class="text-white"></fa-icon>
					</a>
					<a href="https://www.youtube.com/@OPTIMFinance" target="_blank" class="flex h-12 w-12 items-center justify-center bg-black">
						<fa-icon [icon]="['fab', 'youtube']" size="lg" class="text-white"></fa-icon>
					</a>
					<a target="_blank" class="flex h-12 w-12 items-center justify-center bg-black">
						<fa-icon [icon]="['fab', 'facebook-f']" size="lg" class="text-white"></fa-icon>
					</a>
				</div>
			</div>
			<!-- Appointment -->
			<div class="flex flex-col items-center gap-5">
				<div class="flex">
					<img src="assets/images/footer/footer-man.svg" class="w-full" alt="A man" />
				</div>
				<a target="_blank" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3JFgDA3550zFlCeUTwMFeWAJgfzqbVSBvwh8NmYIdGSkAxSCDRzeSnwLdTbuFTnjZdogAE7pfs">
					<button class="flex items-center gap-6 bg-rouge px-6 py-5 text-button font-bold uppercase text-white">
						<span>Prendre un rendez-vous</span>
						<fa-icon [icon]="['fas', 'arrow-right']" size="lg"></fa-icon>
					</button>
				</a>
			</div>
		</div>
	</div>
</div>
<!-- Copyright -->
<div class="bg-black">
	<div class="mx-auto max-w-screen-lg">
		<div class="flex flex-col gap-3 px-5 py-4 lg:flex-row lg:justify-between xl:px-0">
			<!-- Copyright Text -->
			<div class="flex">
				<span class="text-secondary">
					Copyright © {{ year }}.
					<a routerLink="/" class="font-bold uppercase text-white hover:text-primary">
						Optim Finance</a>. Tous les droits sont réservés.
				</span>
			</div>
			<!-- Links -->
			<div class="flex gap-6">
				<a href="#" target="_blank" class="text-secondary hover:text-white">
					Conditions d'utilisation
				</a>
				<a href="#" target="_blank" class="text-secondary hover:text-white">
					Politique de confidentialité
				</a>
				<a href="#" target="_blank" class="text-secondary hover:text-white">Plan du site</a>
			</div>
		</div>
	</div>
</div>