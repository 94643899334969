import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogComponent implements OnInit {

  title: string;
  message: string;
  messageParams: any;
  isWarning: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string, message: string, messageParams: any,
      isWarning?: boolean
    }
  ) {
    this.title = data.title;
    this.message = data.message;
    this.messageParams = data.messageParams;
    this.isWarning = data.isWarning ?? false;
  }

  ngOnInit(): void {
  }

  getClassList(): string {
    if (this.data.isWarning) {
      return 'alert confirm-alert alert-warning';
    }

    return '';
  }
}
