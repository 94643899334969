import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// 3rd Party
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-owl-carousel-o';

// Icons
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import {
	faArrowRight,
	faBars,
	faCircleCheck,
	faCircleExclamation,
	faCircleInfo,
	faFilePdf,
	faFloppyDisk,
	faMagnifyingGlass,
	faMobileScreen,
	faPlus,
	faRotateLeft,
	faRotateRight,
	faShare,
	faSuitcase,
} from '@fortawesome/free-solid-svg-icons';

// Components
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '../core/components/page-header/page-header.component';
import { MaterialModule } from './material.module';
import { CustomSnackbarComponent } from './components/custom-snackbar/custom-snackbar.component';
import { ConfirmActionDialogComponent } from './components/confirm-action-dialog/confirm-action-dialog.component';
import { UppercaseFirstLetterPipe } from './pipes/uppercasefirstletter.pipe';
import { EnumToArrayPipe } from './pipes/enumToArray.pipe';
import { DialogComponent } from './components/dialog/dialog.component';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
	declarations: [
		PageHeaderComponent,
		CustomSnackbarComponent,
		ConfirmActionDialogComponent,
		UppercaseFirstLetterPipe,
		EnumToArrayPipe,
		DialogComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		MaterialModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule
	],
	exports: [
		CarouselModule,
		FontAwesomeModule,
		PageHeaderComponent,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		HttpClientModule
	],
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		// Add an icon to the library for convenient access in other components
		library.addIcons(faFacebookF, faLinkedinIn, faTwitter, faYoutube);
		library.addIcons(
			faArrowRight,
			faBars,
			faCircleCheck,
			faCircleExclamation,
			faCircleInfo,
			faFilePdf,
			faFloppyDisk,
			faMagnifyingGlass,
			faMobileScreen,
			faPlus,
			faRotateLeft,
			faRotateRight,
			faShare,
			faSuitcase
		);
	}
}
