import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'of-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit {
	@ViewChild('stickyMenu') menuElement!: ElementRef;
	menuPosition: any;
	sticky: boolean = false;
	isMobileMenuOpen = false;
	links = [
		{
			label: 'Accueil',
			path: '/',
			display: true,
		},
		{
			label: 'Nos missions',
			path: '/nos-missions',
			display: environment.env_name == 'local',
		},
		{
			label: 'Nos solutions financières',
			path: '/nos-solutions-financieres',
			display: true,
		},
		{
			label: 'Portage salarial',
			path: '/portage-salarial',
			display: true,
		},
		{
			label: 'Auto-entreprise',
			path: '/auto-entreprise',
			display: true,
		},
		{
			label: 'Gestion de société',
			path: '/gestion-de-societe',
			display: true,
		},
		{
			label: 'Simulations',
			path: '/simulations',
			display: environment.env_name == 'local',
		},
		{
			label: 'Documentation',
			path: '/documentation',
			display: environment.env_name == 'local',
		},
		{
			label: 'Coaching',
			path: '/coaching',
			display: environment.env_name == 'local',
		},
	];

	navigationEnd$ = this.router.events.pipe(
		filter((event) => event instanceof NavigationEnd),
		tap(() => this.toggleMenu(true)),
		takeUntilDestroyed()
	);

	constructor(private router: Router) { }

	ngOnInit(): void {
		this.navigationEnd$.subscribe();
	}

	ngAfterViewInit() {
		this.menuPosition = this.menuElement.nativeElement.offsetTop
	}

	toggleMenu(forceClose?: boolean) {
		this.isMobileMenuOpen = forceClose ? false : !this.isMobileMenuOpen;
	}

	@HostListener('window:scroll', ['$event'])
	handleScroll() {
		const windowScroll = window.scrollY;
		if (windowScroll >= this.menuPosition) {
			this.sticky = true;
		} else {
			this.sticky = false;
		}
	}
}
