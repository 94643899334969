import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';


const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('../accueil/accueil.module').then((m) => m.AccueilModule),
	},
	{
		path: 'nos-missions',
		loadChildren: () =>
			import('../nos-missions/nos-missions.module').then((m) => m.NosMissionsModule),
	},
	{
		path: 'nos-solutions-financieres',
		loadChildren: () =>
			import('../nos-solutions-financieres/nos-solutions-financieres.module').then(
				(m) => m.NosSolutionsFinancieresModule
			),
	},
	{
		path: 'portage-salarial',
		loadChildren: () =>
			import('../portage-salarial/portage-salarial.module').then(
				(m) => m.PortageSalarialModule
			),
	},
	{
		path: 'auto-entreprise',
		loadChildren: () =>
			import('../auto-entreprise/auto-entreprise.module').then((m) => m.AutoEntrepriseModule),
	},
	{
		path: 'gestion-de-societe',
		loadChildren: () =>
			import('../gestion-de-societe/gestion-de-societe.module').then(
				(m) => m.GestionDeSocieteModule
			),
	},
	{
		path: 'simulations',
		loadChildren: () =>
			import('../simulations/simulations.module').then((m) => m.SimulationsModule),
	},
	{
		path: 'documentation',
		loadChildren: () =>
			import('../documentation/documentation.module').then((m) => m.DocumentationModule),
	},
	{
		path: 'coaching',
		loadChildren: () => import('../coaching/coaching.module').then((m) => m.CoachingModule),
	},
	{
		path: 'faq',
		loadChildren: () => import('../faq/faq.module').then((m) => m.FaqModule),
	},
	{
		path: 'admin',
		loadChildren: () => import('../admin/admin.module').then((m) => m.AdminModule),
	},
	{
		path: 'login',
		loadChildren: () => import('../login/login.module').then((m) => m.LoginModule),
	},
	{ path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: environment.production ? 'top' : 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
