import { registerLocaleData } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Locales
import localeFr from '@angular/common/locales/fr';

// Import the locale data
registerLocaleData(localeFr);

// Custom
import { AppRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';


@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
	],
	imports: [
		// Angular
		BrowserModule,
		BrowserAnimationsModule,

		// Custom
		AppRoutingModule,
		SharedModule,
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'fr' },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
	],
	bootstrap: [AppComponent],
})
export class CoreModule { }
