<div class="d-flex align-items-center custom-snackbar" [ngClass]="'snack-' + data.type">
  <div class="d-flex icon">
    <mat-icon>{{getIconName(data.type)}}</mat-icon>
  </div>
  <div class="data ps-3 flex-grow-1">{{data.message}}</div>
  <div class="dismiss">
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <ng-container *ngIf="data.action else closeButtonTemplate">
        {{data.action}}
      </ng-container>
      <ng-template #closeButtonTemplate>
        <mat-icon>close</mat-icon>
      </ng-template>

    </button>
  </div>
</div>
