import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'enumToArray'
})
@Injectable({
  providedIn: 'root'
})
export class EnumToArrayPipe implements PipeTransform {
  transform(data: Object) {
    return Object.keys(data);
  }
}
