import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'of-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor() {
		if (environment.production) {
			const rootElement = document.getElementsByTagName('of-root')[0];
			rootElement.removeAttribute('ng-version');
		}
	}
}
