<section class="bg-white">
	<div class="relative mx-auto max-w-screen-xl">
		<!-- Header -->
		<div
			class="relative mb-8 before:absolute before:left-0 before:top-0 before:h-full before:w-full before:bg-bleu lg:mb-0 lg:before:h-4/5"
		>
			<div class="relative z-10 mx-auto max-w-screen-lg">
				<div class="flex gap-9 px-5 py-8 lg:pb-0 xl:px-0">
					<!-- Man Vector -->
					<div
						class="hidden rounded-[232px] border-x-[12px] border-y-[20px] border-white bg-bleu-ciel md:block"
					>
						<img src="assets/images/components/title-section/man.svg" alt="A man" class="pt-2" />
					</div>
					<!-- Details -->
					<div
						class="flex flex-1 flex-col"
						[ngClass]="{
						'md:pt-6': contentWrapper.children.length > 0,
						'md:pt-14': contentWrapper.children.length === 0,
					}"
					>
						<!-- Title -->
						<div class="mb-3 flex items-center gap-x-2.5">
							<div class="h-0.5 w-7 bg-white"></div>
							<h2 class="text-sm/4 font-bold uppercase text-white">{{ title }}</h2>
						</div>
						<!-- Subtitle -->
						<div class="lg:w-2/3">
							<h3
								class="text-h1 font-bold text-white"
								[ngClass]="{
								'mb-5': contentWrapper.children.length > 0,
								'mb-0': contentWrapper.children.length === 0,
							}"
							>
								{{ subtitle }}
							</h3>
						</div>
						<!-- Content -->
						<div #contentWrapper class="contents">
							<ng-content></ng-content>
						</div>
					</div>
				</div>
			</div>
			<!-- Bottom Arc -->
			<div
				class="absolute left-0 h-[32px] w-full bg-bottom-arc bg-cover bg-[0%] md:bg-center lg:top-[80%]"
			></div>
		</div>
	</div>
</section>
