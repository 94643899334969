<div class="dialog-wrapper">
  <h3 mat-dialog-title>
    <mat-icon *ngIf="data.icon?.length && data.icon.length > 0 && !data.list" color="primary" style='width: 19px;' svgIcon="{{data.icon}}"></mat-icon>
    <ng-container *ngIf="data.title">{{ data.title | uppercasefirstletter }}</ng-container>
  </h3>
  <div mat-dialog-content>
    <span class="text" [innerHtml]="data.text | uppercasefirstletter"></span>
    <ng-container *ngIf="data.error">
      <br />
      <span class="text" [innerHtml]="data.error | uppercasefirstletter"></span>
    </ng-container>
    <mat-form-field appearance="outline" *ngIf="data.type == 'prompt'" class="w-100">
      <input type="text" matInput [(ngModel)]="data.value">
    </mat-form-field>

    <ng-container *ngIf="data.list">
      <mat-chip-set>
        <mat-chip *ngFor="let item of data.list">
          <mat-chip-avatar *ngIf="data.icon">
            <i [class]="data.icon" aria-hidden="true"></i>
          </mat-chip-avatar>
          {{item}}
        </mat-chip>
      </mat-chip-set>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <div style="width: 100%; text-align: center">
      <div *ngIf="data.type == 'prompt'">
        <button mat-raised-button (click)="onClick()">
          <mat-icon>arrow_right</mat-icon>
          <span>{{ button_save }}</span>
        </button>
        <button mat-raised-button (click)="onNoClick()">
          <mat-icon>arrow_right</mat-icon>
          <span>{{ button_cancel }}</span>
        </button>
      </div>
      <div *ngIf="data.type == 'confirm'">
        <div class="stylebtn d-flex align-content-around">
          <button mat-raised-button (click)="onClick()">
            <span>{{ text_yes }}</span>
          </button>
          <button mat-raised-button class="cancelButton" (click)="onNoClick()">
            <span>{{ text_no }}</span>
          </button>
        </div>
      </div>
      <div *ngIf="data.type == 'alert'">
        <br />
        <button mat-raised-button (click)="onClick()">
          <mat-icon>arrow_right</mat-icon>
          <span>OK</span>
        </button>
      </div>
    </div>
  </div>
</div>