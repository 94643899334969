<!-- Contact -->
<div class="bg-bleu">
	<div class="mx-auto max-w-screen-xl">
		<div class="flex h-[50px] items-center justify-between gap-6 p-4.5 text-white">
			<!-- Socials -->
			<div class="flex h-full items-center py-2">
				<a href="https://www.linkedin.com/company/optim-finance/mycompany/" target="_blank" title="linkedin" rel="noopener noreferrer" class="border-r-2">
					<fa-icon [icon]="['fab', 'linkedin-in']" class="mx-2"></fa-icon>
				</a>
				<a href="https://www.youtube.com/@OPTIMFinance" target="_blank" title="twitter" rel="noopener noreferrer" class="border-r-2">
					<fa-icon [icon]="['fab', 'youtube']" class="mx-2"></fa-icon>
				</a>
				<a target="_blank" title="facebook" rel="noopener noreferrer" class="border-r-2">
					<fa-icon [icon]="['fab', 'facebook-f']" class="mx-2"></fa-icon>
				</a>
			</div>
			<!-- Phone -->
			<a href="tel:+33159068086" title="téléphone" rel="noopener noreferrer" class="flex items-center">
				<fa-icon [icon]="['fas', 'mobile-screen']" size="lg" class="me-2"></fa-icon>
				<span class="text-sm">+33 1 59 06 80 86</span>
			</a>
			<!-- Appointment -->
			<a target="_blank" href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3JFgDA3550zFlCeUTwMFeWAJgfzqbVSBvwh8NmYIdGSkAxSCDRzeSnwLdTbuFTnjZdogAE7pfs"
				class="hidden md:flex md:flex-1 md:items-center">
				<span class="text-base">Prendre un rendez-vous</span>
				<fa-icon [icon]="['fas', 'arrow-right']" size="lg" class="ms-2"></fa-icon>
			</a>
			<!-- Login/Signup -->
			<div class="hidden md:flex md:items-center md:gap-3">
				<a routerLink="/login" title="Se connecter">Se connecter</a>
				<a routerLink="/" title="S'inscrire">S'inscrire</a>
			</div>
		</div>
	</div>
</div>
<!-- Links -->
<nav class="bg-white" [class.sticky]="sticky" #stickyMenu>
	<div class="relative mx-auto max-w-screen-xl">
		<!-- Main Menu -->
		<div class="flex h-[88px] items-center justify-between gap-11 px-6">
			<!-- Logo -->
			<a routerLink="/goHome">
				<img src="assets/images/logo.svg" alt="Optim Finance Logo" class="max-h-12" />
			</a>
			<!-- Desktop Menu -->
			<ul class="hidden h-full lg:flex lg:flex-1">
				<ng-container *ngFor="let link of links; let i = index">
					<li #rla="routerLinkActive" *ngIf="link.display" [routerLink]="link.path" [routerLinkActiveOptions]="{ exact: i === 0 }" routerLinkActive="font-semibold text-rouge"
						class="flex items-center border-y-4 border-t-transparent hover:cursor-pointer hover:text-rouge" [ngClass]="rla.isActive ? 'border-bleu' : 'border-transparent'">
						<a class="border-primary px-[1.1rem] text-sm/7" [ngClass]="i === 0 ? 'border-s-0' : 'border-s'">
							{{ link.label }}
						</a>
					</li>
				</ng-container>
			</ul>
			<!-- Mobile Menu Button -->
			<button class="h-10 w-10 bg-bleu lg:hidden" (click)="toggleMenu()">
				<fa-icon [icon]="['fas', 'bars']" size="lg" class="text-white"></fa-icon>
			</button>
		</div>
		<!-- Mobile Menu -->
		<ul *ngIf="isMobileMenuOpen" class="absolute left-0 top-[5.5rem] z-50 flex w-full flex-col gap-2 bg-white lg:hidden">
			<li *ngFor="let link of links; let i = index" class="px-4 py-2 text-lg text-primary hover:cursor-pointer hover:text-rouge" [routerLink]="link.path"
				routerLinkActive="border-b-4 border-bleu font-semibold text-rouge" [routerLinkActiveOptions]="{ exact: i === 0 }">
				<a>{{ link.label }}</a>
			</li>
		</ul>
	</div>
</nav>